import React, { FunctionComponent } from 'react';
import Helmet from 'react-helmet';
import { StaticQuery, graphql } from 'gatsby';
import { FixedObject } from 'gatsby-image';
import { getImageWithFit } from '../../lib/helpers';
import { SanityBasicImage } from '../../../graphql-types';

const isThirdPartyDisabled =
  process.env.GATSBY_DISABLE_THIRD_PARTY_INTEGRATIONS === 'true';
const shouldNotIndex = ['staging'].includes(process.env.GATSBY_ACTIVE_ENV);

/**
 * Types
 */
interface SocialLinkObject {
  href: string;
}

export interface SEOProps {
  lang?: string;
  meta?: [];
  noindex?: boolean;
  pageTitle: string;
  pageDescription?: string;
  pageType?: string;
  pageAuthor?: string;
  pageTags?: string[];
  pageImage?: SanityBasicImage;
}

export interface SEOComponentProps extends SEOProps {
  noindex?: boolean;
  siteDescription: string;
  siteName: string;
  siteUrl: string;
  social: {
    links: [];
    twitter: {
      username: string;
    };
  };
  socialHomepage1024: FixedObject;
  socialHomepage1920: FixedObject;
  logoStacked1024: FixedObject;
}

const thirdPartyLinks = [
  { rel: 'dns-prefetch', href: '//beacon-v2.helpscout.net' },
  { rel: 'preconnect', href: '//cdn.sanity.io/' }
];

/**
 * Component definition
 */
export const SEOComponent: FunctionComponent<SEOComponentProps> = ({
  lang = 'en',
  logoStacked1024,
  meta = [],
  noindex = false,
  pageDescription = '',
  pageTitle,
  siteDescription,
  siteName,
  siteUrl,
  social,
  socialHomepage1024,
  socialHomepage1920
}: SEOComponentProps) => {
  const title = pageTitle ? pageTitle : siteName;
  const description = pageDescription ? pageDescription : siteDescription;

  const schemaOrg = [
    {
      '@context': 'https://schema.org',
      '@id': `${siteUrl}/#website`,
      '@type': 'WebSite',
      name: siteName,
      potentialAction: {
        '@type': 'SearchAction',
        'query-input': 'required name=search_term_string',
        target: `${siteUrl}/?s={search_term_string}`
      },
      url: siteUrl
    },
    {
      '@context': 'https://schema.org',
      '@id': `${siteUrl}/#organization`,
      '@type': 'Organization',
      logo: logoStacked1024.src,
      name: siteName,
      sameAs: social.links.map((link: SocialLinkObject) => link.href),
      url: siteUrl
    }
  ];

  const renderThirdPartyLibraries = () =>
    isThirdPartyDisabled
      ? null
      : thirdPartyLinks.map((link, i) => (
          <link rel={link.rel} href={link.href} key={i.toString()} />
        ));

  return (
    <>
      <Helmet
        htmlAttributes={{
          lang
        }}
        title={title}
        meta={meta}
      >
        <link
          rel="manifest"
          href={`${process.env.GATSBY_SITE_URL}/manifest.webmanifest`}
        />
        <link
          rel="icon"
          href="https://static.parsley.health/icons/favicon.ico"
          type="image/x-icon"
        />
        <link rel="dns-prefetch" href="//www.parsleyhealth.com" />
        <link rel="dns-prefetch" href="//cdn.sanity.io" />
        <link rel="dns-prefetch" href="//cdn.segment.com" />
        <link rel="dns-prefetch" href="//static.parsley.health" />
        {renderThirdPartyLibraries()}

        <meta name="description" content={description} />
        <meta property="twitter:image" content={socialHomepage1024.src} />
        <meta property="twitter:title" content={pageTitle} />
        <meta property="twitter:description" content={description} />
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:creator" content={social.twitter.username} />
        <meta property="twitter:site" content={social.twitter.username} />
        <meta property="og:title" content={pageTitle} />
        <meta property="og:description" content={description} />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content={siteName} />
        <meta property="og:url" content={siteUrl} />
        <meta property="og:image" content={socialHomepage1920.src} />
        <meta property="og:image:secure_url" content={socialHomepage1920.src} />
        <meta
          property="og:image:height"
          content={`${socialHomepage1920.height}`}
        />
        <meta
          property="og:image:width"
          content={`${socialHomepage1920.width}`}
        />
        <script type="application/ld+json">{JSON.stringify(schemaOrg)}</script>
      </Helmet>

      {(shouldNotIndex || noindex) && (
        <Helmet>
          <meta name="robots" content="noindex" />
          <meta name="googlebot" content="noindex" />
        </Helmet>
      )}
    </>
  );
};

const query = graphql`
  query SEOQuery {
    site {
      siteMetadata {
        siteUrl
        social {
          links {
            href
          }
          twitter {
            username
          }
        }
      }
    }
    sanitySiteSettings {
      title
      description
      socialHomepageFile: socialNetworksImage {
        hotspot {
          y
          x
          width
          height
        }
        crop {
          bottom
          left
          right
          top
        }
        asset {
          url
        }
      }
      logoStackedFile: stackedLogo {
        asset {
          fixed1024: fixed(width: 1024, height: 512) {
            base64
            width
            height
            src
            srcSet
            srcWebp
            srcSetWebp
          }
        }
      }
    }
  }
`;

export const SEO: FunctionComponent<SEOProps> = (props: SEOProps) => {
  const imageSocial = props.pageImage ? props.pageImage : null;
  return (
    <StaticQuery
      query={query}
      render={({ site, sanitySiteSettings }) => (
        <SEOComponent
          socialHomepage1024={
            imageSocial
              ? getImageWithFit(imageSocial, 1024, 512)
              : getImageWithFit(
                  sanitySiteSettings.socialHomepageFile,
                  1024,
                  512
                )
          }
          socialHomepage1920={
            imageSocial
              ? getImageWithFit(imageSocial, 1920, 1184)
              : getImageWithFit(
                  sanitySiteSettings.socialHomepageFile,
                  1920,
                  1184
                )
          }
          socialHomepageFile={imageSocial}
          logoStacked1024={sanitySiteSettings.logoStackedFile.asset.fixed1024}
          siteName={sanitySiteSettings.title}
          siteDescription={sanitySiteSettings.description}
          {...site.siteMetadata}
          {...props}
        />
      )}
    />
  );
};

export default SEO;
